import React from 'react';
import { WrenchScrewdriverIcon } from '@heroicons/react/24/solid';

const tools = [
  // { name: '', img: '' },
  { name: 'bandsaw', img: 'bandsaw.jpg' },
  { name: 'circular saw', img: 'circular_saw.jpg' },
  { name: 'compound miter saw', img: 'miter_saw.jpg' },
  { name: 'disc sander', img: 'disc_sander.jpg' },
  { name: 'hole saw cutters', img: 'hole_saw.jpg' },
  { name: 'japanese hand saw', img: 'japanese_hand_saw.jpg' },
  { name: 'jointer', img: 'jointer.jpg' },
  { name: 'lathe', img: null },
  { name: 'nail gun', img: 'nailgun.jpg' },
  { name: 'orbital sander', img: 'orbital_sander.jpg' },
  { name: 'panel saw', img: 'panel_saw.jpg' },
  { name: 'planner', img: 'dewalt.jpg' },
  { name: 'table saw', img: 'tablesaw.jpg' },
  { name: 'torque wrench', img: '' },
];
const Tools = () => {
  return (
    <div className="m-6 w-full">
      <div class="grid grid-cols-2 md:grid-cols-4 gap-4">
        {tools.map((tool) => {
          return (
            <div className="flex flex-col justify-between max-w-sm p-4 bg-white border border-gray-200 rounded-md shadow hover:border-purple-700">
              {tool.img ? (
                <img
                  className="p-2"
                  src={`/img/wtc/tools/${tool.img}`}
                  alt={tool.name}
                />
              ) : (
                <div className="p-4">
                  <WrenchScrewdriverIcon />
                </div>
              )}

              <h5 className="mb-2 font-normal tracking-tight text-gray-900 text-center">
                {tool.name}
              </h5>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Tools;
